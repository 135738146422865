<template>
  <section class="statistics ">
    <div class="statistics__items">
      <div
        v-for="(statistic, i) in getStatistics"
        :key="i"
        class="statistics-item"
      >
        <BaseImg
          :src="srcImg(i)"
          lazy
          :sizes="{lg:['32','32'], xs:['24','24']}"
        />
        <span class="statistics-item__no">
          {{ statistic }}
        </span>
        <span class="statistics-item__name">{{ title(i) }}</span>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SectionStatistics',
  computed: {
    ...mapGetters('global', ['getStatistics'])
  },
  methods: {
    srcImg (statistic) {
      const icons = {
        tutoring_hours: require('@images/public/home/statistics/time.gif'),
        online_students: require('@images/public/home/statistics/student.gif'),
        students_feedback: require('@images/public/home/statistics/star.gif')
      }
      return icons[statistic]
    },
    title (statistic) {
      const icons = {
        tutoring_hours: 'Tutoring hours',
        online_students: 'Online Students',
        students_feedback: 'Students feedback'
      }
      return icons[statistic]
    }
  }
}
</script>

<style lang="scss" scoped>
.statistics {
  &__items {
    @apply container flex space-x-4 justify-center;
  }
  &-item {
    @apply relative text-center text-white flex flex-col items-center lg:w-[150px] w-1/3;
    &__no {
      @apply text__headline-4-xs--bold lg:text__headline-5-md--bold text-title mt-2;
    }
    &__name {
      @apply text__caption-xs--regular lg:text__body-3-md--regular mt-1 text-body-2;
    }
  }
}
</style>
